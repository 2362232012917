/*
 * @Description: AKJERRT
 * @Date: 2022-09-15 11:59:22
 * @LastEditTime: 2022-09-15 15:52:32
 * @FilePath: \linkgap-front\src\views\aarget_budget\components\basis.js
 */
const qs = require('qs')
export const mixin = {
  data() {
    return {
      dealers: []
    }
  },
  mounted() {
    const postData = Object.assign({
      dealerClassify: 1, //下单时选择的经销商只能时下单经销商
      pageNumber: 1,
      pageSize: 999,
      verify: 1,
    })
    this.axios.get(`/api/dealer/dealer/dealerInfo/listForCurrOrderFirm?${qs.stringify(postData)}`).then((res) => {
      // 获取大区事务所经销商数据
      const data = res.body.records
      this.dealers = data
      console.log(JSON.parse(JSON.stringify(data)))
    });
  },
  methods: {
    submit() {
      console.log('提交');
    },
  }
};